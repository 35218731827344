import inst, { PluginFunc, UnitType } from 'dayjs';

declare module 'dayjs' {
    interface Dayjs {
        ceil(amount: number, unit: UnitType): inst.Dayjs;
    }
}
const ceil: PluginFunc = (option, dayjsClass) => {
    dayjsClass.prototype.ceil = function (amount, unit) {
        return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
    };
};
export default ceil;
